<app-navbar-style-four></app-navbar-style-four>

<div class="inner-banner">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Testimonials</h3>
            <ul>
                <li><a href="index.html">Home</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Testimonials</li>
            </ul>
        </div>
    </div>

    <div class="inner-banner-shape">
        <div class="shape-one"><img src="assets/img/inner-banner/banner-shape1.png" alt="Images"></div>
        <div class="shape-two"><img src="assets/img/inner-banner/banner-shape2.png" alt="Images"></div>
        <div class="shape-three"><img src="assets/img/inner-banner/banner-shape3.png" alt="Images"></div>
        <div class="inner-banner-dots"><img src="assets/img/shape/dots-shape.png" alt="Images"></div>
    </div>
</div>

<div class="testimonials-widget-area pt-50 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span class="sp-before sp-after">Testimonials</span>
            <h2 class="h2-color2">Our Clients Feedback</h2> 
        </div>

        <div class="row pt-45">
            <div class="col-lg-6">
                <div class="testimonial-widget-item">
                    <p>I chose Zinka because of their value And incredible superior customer Service they really awesome treated me like family.</p>
                    <div class="content">
                        <img src="assets/img/testimonial/testimonial-img1.png" alt="Images">
                        <div class="content-title">
                            <h3>David McLean</h3>
                            <span>CEO & Manager</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="testimonial-widget-item">
                    <p>I chose Zinka because of their value And incredible superior customer Service they really awesome treated me like family.</p>
                    <div class="content">
                        <img src="assets/img/testimonial/testimonial-img2.png" alt="Images">
                        <div class="content-title">
                            <h3>Jermin Jekson</h3>
                            <span>Marketing Manager</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="testimonial-widget-item">
                    <p>I chose Zinka because of their value And incredible superior customer Service they really awesome treated me like family.</p>
                    <div class="content">
                        <img src="assets/img/testimonial/testimonial-img3.png" alt="Images">
                        <div class="content-title">
                            <h3>Julfiker Jeain</h3>
                            <span>CEO At Ostino</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="testimonial-widget-item">
                    <p>I chose Zinka because of their value And incredible superior customer Service they really awesome treated me like family.</p>
                    <div class="content">
                        <img src="assets/img/testimonial/testimonial-img4.png" alt="Images">
                        <div class="content-title">
                            <h3>Hobler Jeain</h3>
                            <span> General Manager</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 col-md-12">
                <div class="pagination-area text-center">
                    <a routerLink="/testimonials" class="prev page-numbers"><i class="bx bx-chevron-left"></i></a>
                    <span class="page-numbers current" aria-current="page">1</span>
                    <a routerLink="/testimonials" class="page-numbers">2</a>
                    <a routerLink="/testimonials" class="page-numbers">3</a>
                    <a routerLink="/testimonials" class="page-numbers">4</a>
                    <a routerLink="/testimonials" class="next page-numbers"><i class="bx bx-chevron-right"></i></a>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="brand-logo-area  pt-100">
    <div class="container-fluid">
        <div class="container-max">
            <div class="brand-logo-slider owl-carousel owl-theme">
                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo1.png" alt="Images">
                </div>
                
                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo2.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo3.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo4.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo5.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo6.png" alt="Images">
                </div>
            </div>
        </div>
    </div>
</div>

<app-footer-style-two></app-footer-style-two>