<app-navbar-style-two></app-navbar-style-two>

<div class="inner-banner">
    <div class="container">
        <div class="inner-title text-center">
            <h3>CREDITS & WELLS</h3>
            <ul>
                <li><a href="index.html">Home</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Credits</li>
            </ul>
        </div>
    </div>

    <!-- <div class="inner-banner-shape">
        <div class="shape-one"><img src="assets/img/inner-banner/banner-shape1.png" alt="Images"></div>
        <div class="shape-two"><img src="assets/img/inner-banner/banner-shape2.png" alt="Images"></div>
        <div class="shape-three"><img src="assets/img/inner-banner/banner-shape3.png" alt="Images"></div>
        <div class="inner-banner-dots"><img src="assets/img/shape/dots-shape.png" alt="Images"></div>
    </div> -->
</div>

<section class="module">
    <div class="container">
      <div class="row">
        <div class="col-sm-8 col-sm-offset-2">
          <h4 class="font-alt mb-0">Credits</h4>
          <hr class="divider-w mt-10 mb-20">
          <div class="alert alert-success" role="alert"><a href='https://www.freepik.com/vectors/education-app'>Education app vector created by macrovector - www.freepik.com</a></div>
          <div class="alert alert-info" role="alert"><a href='https://www.freepik.com/vectors/interpretation'>Interpretation vector created by storyset - www.freepik.com</a></div>
          <!-- <div class="alert alert-warning" role="alert"><a href="https://iconscout.com/icons/nodejs" target="_blank">Nodejs Icon</a> by <a href="https://iconscout.com/contributors/icon-mafia" target="_blank">Icon Mafia</a></div> -->
          <!-- <div class="alert alert-danger" role="alert"><a href="https://www.flaticon.com/free-icons/cloud-computing" title="cloud computing icons">Cloud computing icons created by vectorsmarket15 - Flaticon</a></div> -->
          
          <!-- <div class="alert alert-success" role="alert"><a href="https://www.flaticon.com/free-icons/docker" title="docker icons">Docker icons created by Freepik - Flaticon</a></div> -->
          <!-- <div class="alert alert-info" role="alert"><a href="https://www.flaticon.com/free-icons/api" title="api icons">Api icons created by DinosoftLabs - Flaticon</a></div> -->
          <!-- <div class="alert alert-danger" role="alert"><a href="https://icons8.com/icon/UFXRpPFebwa2/mysql-logo">MySQL Logo</a> icon by <a target="_blank" href="https://icons8.com">Icons8</a></div> -->
          <!-- <div class="alert alert-danger" role="alert"><a href="https://www.flaticon.com/free-icons/css" title="css icons">Css icons created by Freepik - Flaticon</a></div> -->

          <!-- <div class="alert alert-success" role="alert"><a href='https://www.freepik.com/photos/backend'>Backend photo created by pressfoto - www.freepik.com</a></div> -->
          <!-- <div class="alert alert-info" role="alert"><a href="https://www.freepik.com/photos/blank-template">Blank template photo created by benzoix - www.freepik.com</a></div> -->
          <!-- <div class="alert alert-danger" role="alert"><a href="https://www.freepik.com/vectors/fusion">Fusion vector created by starline - www.freepik.com</a></div> -->
          <!-- <div class="alert alert-danger" role="alert"><a href="https://www.freepik.com/vectors/computer-illustration">Computer illustration vector created by macrovector - www.freepik.com</a></div> -->

          <!-- <h4 class="font-alt mb-0">Alerts with icons</h4>
          <hr class="divider-w mt-10 mb-20">
          <div class="alert alert-success" role="alert">
            <button class="close" type="button" data-dismiss="alert" aria-hidden="true">&times;</button><i class="fa fa-cog fa-spin"></i><strong>Alert!</strong> The European languages are members of the same family.
          </div>
          <div class="alert alert-info" role="alert">
            <button class="close" type="button" data-dismiss="alert" aria-hidden="true">&times;</button><i class="fa fa-cutlery"></i><strong>Alert!</strong> The European languages are members of the same family.
          </div>
          <div class="alert alert-warning" role="alert">
            <button class="close" type="button" data-dismiss="alert" aria-hidden="true">&times;</button><i class="fa fa-diamond"></i><strong>Alert!</strong> The European languages are members of the same family.
          </div>
          <div class="alert alert-danger" role="alert">
            <button class="close" type="button" data-dismiss="alert" aria-hidden="true">&times;</button><i class="fa fa-coffee"></i><strong>Alert!</strong> The European languages are members of the same family.
          </div>
          <h4 class="font-alt mb-0">Wells</h4>
          <hr class="divider-w mt-10 mb-20">
          <div class="well well-lg"><strong>Well!</strong> The European languages are members of the same family.</div>
          <div class="well"><strong>Well!</strong> The European languages are members of the same family.</div>
          <div class="well well-sm"><strong>Well!</strong> The European languages are members of the same family.</div> -->
        </div>
      </div>
    </div>
  </section>



<app-footer-style-two></app-footer-style-two>