import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ai-ml',
  templateUrl: './ai-ml.component.html',
  styleUrls: ['./ai-ml.component.scss']
})
export class AiMlComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
