<footer class="footer-area footer-bg2 pt-100">
    <div class="container">
        <div class="footer-midal pb-70">
            <div class="row">
                <div class="col-lg-4 col-sm-7">
                    <div class="footer-widget">
                        <div class="footer-img">
                            <img src="assets/img/logo/logo3.png" alt="Images">
                        </div>
                        <p>Longroute is a company working on different types of software technologies, distribution, and software product development. We make up the software industry.</p>
                        <div class="footer-social-icon">
                            <ul class="social-link">
                                <!-- <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>  -->
                                <li><a href="https://twitter.com/LongrouteTech" target="_blank"><i class='bx bxl-twitter'></i></a></li> 
                                <li><a href="https://www.instagram.com/longroutetech" target="_blank"><i class='bx bxl-instagram'></i></a></li> 
                                <!-- <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>  -->
                                <!-- <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li>  -->
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="col-lg-2 col-sm-5">
                    <div class="footer-widget">
                        <h3>Resources</h3>

                        <ul class="footer-list">
                            <!-- <li><a routerLink="/ai-startup">Our Scientists</a></li> -->
                            <li><a routerLink="/">Our Services</a></li>
                            <!-- <li><a routerLink="/ai-startup">Testimonials</a></li> -->
                            <!-- <li><a routerLink="/">SaaS Solutions</a></li> -->
                            <!-- <li><a routerLink="/">eCommerce</a></li> -->
                        </ul>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-7">
                    <div class="footer-widget pl-5">
                        <h3>Company</h3>

                        <ul class="footer-list">
                            <li><a routerLink="/">Home</a></li>
                            <li><a routerLink="/#">About</a></li>
                            <!-- <li><a routerLink="/case-study">Case Studies</a></li> -->
                            <li><a routerLink="/#">Our Blog</a></li>
                            <li><a routerLink="/contact">Contact</a></li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-5">
                    <div class="footer-widget">
                        <h3>Address</h3>

                        <ul class="footer-list-two">
                            <li><i class='flaticon-telephone'></i><a href="tel:+918770322171">+91 87703 22171</a></li>
                            <li><i class='flaticon-telephone'></i><a href="tel:+919977550502">+91 99 77 55 05 02</a></li>
                            <li><i class='flaticon-email-1'></i><a href="mailto:ankit@longroute.in">ankit@longroute.in</a></li>
                            <li><i class='flaticon-place'></i>Nandini Nagar, Durg, Chhattisgarh, India, 490036</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div class="copy-right-area">
            <div class="row">
                <div class="col-lg-8">
                    <div class="copy-right-text text-start">
                        <p>Copyright @2022 Longroute Technologies. All Rights Reserved by <a href="https://longroute.in/" target="_blank">Longroute</a> </p>
                    </div>
                </div>

                <div class="col-lg-4">
                    <div class="copy-right-list">
                        <ul>
                            <!-- <li><a routerLink="/privacy-policy"> Privacy Policy</a></li> -->
                            <!-- <li><a routerLink="/terms-conditions"> Terms & Conditions</a></li> -->
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>