<app-navbar-style-one></app-navbar-style-one>

<div class="banner-area">
    <div class="container-fluid">
        <div class="container-max-2">
            <div class="col-lg-7 col-md-12">
                <div class="banner-content">
                    <div class="title">
                        <i class="flaticon-idea"></i>
                        <span>The Generator</span>
                    </div>
                    <h1>Possible to Link <b>Actual Customer</b></h1>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor avida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                    <div class="banner-btn">
                        <a routerLink="/contact" class="get-btn">Get started<i class='bx bx-plus'></i></a>
                        <a href="tel:+123-098-456-098" class="call-btn"><i class='bx bx-plus'></i>+123-098-456-098</a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="banner-shape-right"><img src="assets/img/home1/home-one-shape.png" alt="Images"></div>
    <div class="banner-img"><img src="assets/img/home1/home1.png" alt="Images"></div>
    <div class="banner-shape">
        <div class="shape1"><img src="assets/img/home1/home-one-shape3.png" alt="Images"></div>
        <div class="shape2"><img src="assets/img/home1/home-one-shape1.png" alt="Images"></div>
        <div class="shape3"><img src="assets/img/home1/home-one-shape2.png" alt="Images"></div>
        <div class="shape4"><img src="assets/img/home1/home-one-shape4.png" alt="Images"></div>
        <div class="shape5"><img src="assets/img/home1/home-one-shape5.png" alt="Images"></div>
    </div>
</div>

<div class="brand-area">
    <div class="container-fluid">
        <div class="brand-max">
            <div class="brand-title">
                <h3>Over <a href="#" target="_blank">150,000</a> supporter all ovet the world</h3>
            </div>

            <div class="brand-slider owl-carousel owl-theme">
                <div class="brand-item">
                    <a href="#" target="_blank"><img src="assets/img/brand/brand1.png" alt="Images"></a>
                </div>

                <div class="brand-item">
                    <a href="#" target="_blank"><img src="assets/img/brand/brand2.png" alt="Images"></a>
                </div>

                <div class="brand-item">
                    <a href="#" target="_blank"><img src="assets/img/brand/brand3.png" alt="Images"></a>
                </div>

                <div class="brand-item">
                    <a href="#" target="_blank"><img src="assets/img/brand/brand4.png" alt="Images"></a>
                </div>

                <div class="brand-item">
                    <a href="#" target="_blank"><img src="assets/img/brand/brand5.png" alt="Images"></a>
                </div>

                <div class="brand-item">
                    <a href="#" target="_blank"><img src="assets/img/brand/brand6.png" alt="Images"></a>
                </div>

                <div class="brand-item">
                    <a href="#" target="_blank"><img src="assets/img/brand/brand2.png" alt="Images"></a>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="about-area pt-100 pb-70">
    <div class="container-fluid">
        <div class="container-max">
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="about-img">
                        <img src="assets/img/about/about-img1.png" alt="Images">
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="about-content">
                        <div class="section-title">
                            <span class="sp-after">About us</span>
                            <h2 class="h2-color">Grow Your Business <b>With Our Agency</b></h2>
                        </div>
                        <h3>We have 30 yearsOur strategy includes consistently evolving, to ensure we’re producing exceptional SEO for business.</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas vitae tempor nisl. Etiam magna augue, tempus eget suscipit et, maximus hendrerit nunc. Nunc elementum non ipsum vitae molestie. Donec vestibulum lobortis congue. Mauris sed tincidunt leo Suspendisse accumsan.</p>
                        <div class="about-btn">
                            <a routerLink="/about" class="default-btn">Learn More<i class='bx bx-plus'></i></a>
                            <a href="https://www.youtube.com/watch?v=07d2dXHYb94" class="play-btn popup-btn"><i class="flaticon-play-button"></i><span> Watch 1min <br>Play demo</span></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="about-shape">
        <div class="shape-1"><img src="assets/img/about/about-shape1.png" alt="Images"></div>
        <div class="shape-2"><img src="assets/img/shape/shape1.png" alt="Images"></div>
        <div class="shape-3"><img src="assets/img/shape/shape2.png" alt="Images"></div>
        <div class="shape-4"><img src="assets/img/shape/shape3.png" alt="Images"></div>
        <div class="shape-5"><img src="assets/img/shape/shape4.png" alt="Images"></div>
    </div>
</div>

<div class="counter-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="single-counter">
                    <i class="flaticon-confetti"></i>
                    <div class="content">
                        <h3>10 +</h3>
                        <p>GLORIOUS YEARS</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-counter">
                    <i class="flaticon-project"></i>
                    <div class="content">
                        <h3>670 +</h3>
                        <p>PROJECTS DONE</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-counter">
                    <i class="flaticon-customers"></i>
                    <div class="content">
                        <h3>560 +</h3>
                        <p>ACTIVE CLIENTS</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-counter">
                    <i class="flaticon-financial-advisor"></i>
                    <div class="content">
                        <h3>56 +</h3>
                        <p>TEAM ADVISORS</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="progress-area pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span class="sp-after">Real Progress</span>
            <h2 class="h2-color">Super Great Things From <b>Your Desire Wishes</b></h2>
        </div>

        <div class="row pt-45">
            <div class="col-lg-4 col-sm-6">
                <div class="progress-card pr-bg-color-1">
                    <img src="assets/img/progress-icon/progress-icon1.png" alt="Images">
                    <h3>28,998</h3>
                    <p>RANKING KEYWORDS</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="progress-card pr-bg-color-2">
                    <img src="assets/img/progress-icon/progress-icon2.png" alt="Images">
                    <h3>600%</h3>
                    <p>ANNUAL ORGANIC TRAFFIC</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 offset-sm-3  offset-lg-0">
                <div class="progress-card pr-bg-color-3">
                    <img src="assets/img/progress-icon/progress-icon3.png" alt="Images">
                    <h3>50,234</h3>
                    <p>RANKING KEYWORDS</p>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="seo-area">
    <div class="container-fluid">
        <div class="seo-width">
            <div class="row align-items-center">
                <div class="col-lg-8 col-md-12">
                    <div class="seo-form">
                        <h2>What is My SEO Score?</h2>

                        <div class="seo-form-area">
                            <form class="seo-form-option">
                                <div class="row">
                                    <div class="col-lg-4 col-sm-6 col-md-4">
                                        <div class="form-group">
                                            <i class='flaticon-place'></i>
                                            <input class="form-control form-border" type="text" placeholder="Your Website URL">
                                        </div>
                                    </div>
        
                                    <div class="col-lg-4 col-sm-6 col-md-4">
                                        <div class="form-group">
                                            <i class='flaticon-vision'></i>
                                            <input class="form-control" type="email"  placeholder="Enter Your Email">
                                        </div>
                                    </div>

                                    <div class="col-lg-4 col-sm-6 col-md-4 offset-sm-3 offset-md-0 offset-lg-0">
                                        <button type="submit" class="default-btn">
                                            Get started
                                            <i class='bx bx-plus'></i>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-12">
                    <div class="seo-img">
                        <img src="assets/img/seo-rank.png" alt="Images">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="services-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sp-after">Services</span>
            <h2 class="h2-color">We’re Flexible to <b>Provide You Best</b></h2>
        </div>

        <div class="row pt-45">
            <div class="col-lg-4 col-md-12">
                <div class="services-card-into">
                    <div class="services-card">
                        <a routerLink="/services-details"><img src="assets/img/service/service-icon1.png" alt="Images"></a>
                        <h3><a routerLink="/services-details">App Development</a></h3>
                        <p>Lorem ipsum dolor sit amet, aut odiut perspiciatis unde omnis iste natus odit aut fugitsed quia consequuntur alquam quaerat voluptatem </p>
                        <div class="services-card-shape">
                            <img src="assets/img/service/service-shape.png" alt="Images">
                        </div>
                    </div>
                    <div class="services-dots"><img src="assets/img/service/dots.png" alt="Images"></div>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="services-card">
                    <a routerLink="/services-details"><img src="assets/img/service/service-icon2.png" alt="Images"></a>
                    <h3><a routerLink="/services-details">Social Media Marketing</a></h3> 
                    <p>Lorem ipsum dolor sit amet, aut odiut perspiciatis unde omnis iste natus odit aut fugitsed quia consequuntur alquam quaerat voluptatem </p>
                    <div class="services-card-shape"><img src="assets/img/service/service-shape.png" alt="Images"></div>
                </div>
                
                <div class="services-card">
                    <a routerLink="/services-details"><img src="assets/img/service/service-icon3.png" alt="Images"></a>
                    <h3><a routerLink="/services-details">Content Marketing</a></h3>
                    <p>Lorem ipsum dolor sit amet, aut odiut perspiciatis unde omnis iste natus odit aut fugitsed quia consequuntur alquam quaerat voluptatem </p>
                    <div class="services-card-shape"><img src="assets/img/service/service-shape.png" alt="Images"></div>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="services-card">
                    <a routerLink="/services-details"><img src="assets/img/service/service-icon4.png" alt="Images"></a>
                    <h3><a routerLink="/services-details">SEO Optimization</a></h3>
                    <p>Lorem ipsum dolor sit amet, aut odiut perspiciatis unde omnis iste natus odit aut fugitsed quia consequuntur alquam quaerat voluptatem </p>
                    <div class="services-card-shape"><img src="assets/img/service/service-shape.png" alt="Images"></div>
                </div>

                <div class="services-card">
                    <a routerLink="/services-details"><img src="assets/img/service/service-icon5.png" alt="Images"></a>
                    <h3><a routerLink="/services-details">Web Development</a></h3>
                    <p>Lorem ipsum dolor sit amet, aut odiut perspiciatis unde omnis iste natus odit aut fugitsed quia consequuntur alquam quaerat voluptatem </p>
                    <div class="services-card-shape"><img src="assets/img/service/service-shape.png" alt="Images"></div>
                </div>

                <div class="services-card">
                    <a routerLink="/services-details"><img src="assets/img/service/service-icon6.png" alt="Images"></a>
                    <h3><a routerLink="/services-details">PPC Advertising</a></h3>
                    <p>Lorem ipsum dolor sit amet, aut odiut perspiciatis unde omnis iste natus odit aut fugitsed quia consequuntur alquam quaerat voluptatem </p>
                    <div class="services-card-shape"><img src="assets/img/service/service-shape.png" alt="Images"></div>
                </div>
            </div>
        </div>
    </div>

    <div class="services-shape">
        <div class="services-bg"><img src="assets/img/service/service-bg.png" alt="Images"></div>
        <div class="shape1"><img src="assets/img/shape/shape4.png" alt="Images"></div>
        <div class="shape2"><img src="assets/img/shape/shape2.png" alt="Images"></div>
    </div>
</div>

<div class="work-area pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span class="sp-after">Working Process</span>
            <h2 class="h2-color">Simple & Clean <b>Work Process</b></h2>
        </div>

        <div class="row pt-45">
            <div class="col-lg-4 col-sm-6">
                <div class="work-card">
                    <h2>01</h2>
                    <h3>Onsite Optimization</h3>
                    <p>Lorem ipsum dolor sit amet, aut odiut perspiciatis unde omnis iste natus odit afugitsed quia consequuntur </p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="work-card active">
                    <h2>02</h2>
                    <h3>Keyword Research</h3>
                    <p>Lorem ipsum dolor sit amet, aut odiut perspiciatis unde omnis iste natus odit afugitsed quia consequuntur </p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
                <div class="work-card">
                    <h2>03</h2>
                    <h3>Link Building</h3>
                    <p>Lorem ipsum dolor sit amet, aut odiut perspiciatis unde omnis iste natus odit afugitsed quia consequuntur </p>
                </div>
            </div>
        </div>
    </div>

    <div class="work-shape"><img src="assets/img/shape/work-shape.png" alt="Images"></div>
</div>

<div class="pricing-area ptb-100">
    <div class="container-fluid">
        <div class="section-title text-center">
            <span class="sp-after">Pricing Plan</span>
            <h2 class="h2-color">Price & Plans <b>Packages</b></h2>
        </div>

        <div class="price-width">
            <div class="row">
                <div class="col-lg-4 col-sm-6">
                    <div class="pricing-card">
                        <div class="pricing-card-into color-bg1">
                            <i class="flaticon-banner pricing-icon color-1"></i>
                            <h3 class="color-1">STANDARD</h3>
                            <div class="price-rate">
                                <h2 class="color-1">$ 69.99</h2>
                                <span class="color-1">Monthly Package</span>
                            </div>
                            <ul>
                                <li><i class='bx bx-check'></i> Social Media Marketing</li>
                                <li><i class='bx bx-check'></i> 2.100 Keywords</li>
                                <li><i class='bx bx-check'></i> One Way Link Building</li>
                                <li><del>5 Free Optimization</del></li>
                                <li><del>3 Press Releases</del></li>
                            </ul>
                            <a routerLink="/" class="purchase-btn button-bg1">PURCHASE NOW</a>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6">
                    <div class="pricing-card">
                        <div class="pricing-card-into color-bg2">
                            <i class="flaticon-mortgage-loan pricing-icon color-2"></i>
                            <h3 class="color-2">ECONOMY</h3>
                            <div class="price-rate">
                                <h2 class="color-2">$ 79.99</h2>
                                <span class="color-2">Monthly Package</span>
                            </div>
                            <ul>
                                <li><i class='bx bx-check'></i> Social Media Marketing</li>
                                <li><i class='bx bx-check'></i> 2.100 Keywords</li>
                                <li><i class='bx bx-check'></i> One Way Link Building</li>
                                <li><i class='bx bx-check'></i> 5 Free Optimization</li>
                                <li><del>3 Press Releases</del></li>
                            </ul>
                            <a routerLink="/" class="purchase-btn button-bg2">PURCHASE NOW</a>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
                    <div class="pricing-card">
                        <div class="pricing-card-into color-bg3">
                            <i class="flaticon-processing pricing-icon color-3"></i>
                            <h3 class="color-3">EXECUTIVE</h3>
                            <div class="price-rate">
                                <h2 class="color-3">$ 89.99</h2>
                                <span class="color-3">Monthly Package</span>
                            </div>
                            <ul>
                                <li><i class='bx bx-check'></i> Social Media Marketing</li>
                                <li><i class='bx bx-check'></i> 2.100 Keywords</li>
                                <li><i class='bx bx-check'></i> One Way Link Building</li>
                                <li><i class='bx bx-check'></i> 5 Free Optimization</li>
                                <li><i class='bx bx-check'></i> 3 Press Releases</li>
                            </ul>
                            <a routerLink="/" class="purchase-btn button-bg3">PURCHASE NOW</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="pricing-shape">
        <div class="shape1"><img src="assets/img/shape/shape7.png" alt="Images"></div>
        <div class="shape2"><img src="assets/img/shape/shape4.png" alt="Images"></div>
        <div class="shape3"><img src="assets/img/shape/shape5.png" alt="Images"></div>
        <div class="shape4"><img src="assets/img/shape/shape6.png" alt="Images"></div>
        <div class="shape5"><img src="assets/img/shape/shape4.png" alt="Images"></div>
        <div class="shape6"><img src="assets/img/shape/shape8.png" alt="Images"></div>
    </div>
</div>

<div class="team-area pt-100 pb-70">
    <div class="container-fluid p-0 m-0">
        <div class="section-title text-center">
            <span class="sp-after">Our Specialists</span>
            <h2 class="h2-color">Meet Our <b>Leadership Team</b></h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud </p>
        </div>

        <div class="team-slider owl-carousel owl-theme pt-45">
            <div class="team-item">
                <a routerLink="/team" class="team-img team-bg-before"><img src="assets/img/team/team-img1.jpg" alt="Images"></a>
                <div class="team-content">
                    <h3><a routerLink="/team">Hobler Jeain</a></h3>
                    <span>General Manager</span>
                    <ul class="social-link">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li> 
                    </ul>
                </div>
            </div>

            <div class="team-item">
                <a routerLink="/team" class="team-img team-bg-before-2"><img src="assets/img/team/team-img2.jpg" alt="Images"></a>
                <div class="team-content">
                    <h3><a routerLink="/team">Jermin Jekson</a></h3>
                    <span>Marketing Manager</span>
                    <ul class="social-link">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li> 
                    </ul>
                </div>
            </div>

            <div class="team-item">
                <a routerLink="/team" class="team-img team-bg-before-3"><img src="assets/img/team/team-img3.jpg" alt="Images"></a>
                <div class="team-content">
                    <h3><a routerLink="/team">Cristiono Kopper</a></h3>
                    <span>Web Developer</span>
                    <ul class="social-link">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li> 
                    </ul>
                </div>
            </div>

            <div class="team-item">
                <a routerLink="/team" class="team-img team-bg-before"><img src="assets/img/team/team-img4.jpg" alt="Images"></a>
                <div class="team-content">
                    <h3><a routerLink="/team">Julfiker Jeain</a></h3>
                    <span>CEO At Ostino</span>
                    <ul class="social-link">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li> 
                    </ul>
                </div>
            </div>

            <div class="team-item">
                <a routerLink="/team" class="team-img team-bg-before-2"><img src="assets/img/team/team-img5.jpg" alt="Images"></a>
                <div class="team-content">
                    <h3><a routerLink="/team">Rickey poltin</a></h3>
                    <span>Area Manager</span>
                    <ul class="social-link">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li> 
                    </ul>
                </div>
            </div>

            <div class="team-item">
                <a routerLink="/team" class="team-img team-bg-before-3"><img src="assets/img/team/team-img6.jpg" alt="Images"></a>
                <div class="team-content">
                    <h3><a routerLink="/team">Jeck Keplin</a></h3>
                    <span>Web Developer</span>
                    <ul class="social-link">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li> 
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="team-shape">
        <div class="team-shape1"><img src="assets/img/shape/team-shape.png" alt="Images"></div>
        <div class="team-shape2"><img src="assets/img/shape/shape7.png" alt="Images"></div>
        <div class="team-shape3"><img src="assets/img/shape/shape6.png" alt="Images"></div>
        <div class="team-shape4"><img src="assets/img/shape/shape2.png" alt="Images"></div>
    </div>
</div>

<div class="blog-area blog-bg pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="blog-title">
                    <div class="section-title">
                        <span class="sp-after">Our Blogs</span>
                        <h2 class="h2-color">Latest Valuable <b>Insights</b></h2>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua enim ad minim</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="blog-btn">
                    <a routerLink="/blog" class="default-btn">See all news<i class='bx bx-plus'></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="blog-card">
                    <a routerLink="/blog-details"><img src="assets/img/blog/blog1.png" alt="Images"></a>
                    <div class="content">
                        <ul>
                            <li><i class='bx bx-time-five'></i> 09 April 2020</li>
                            <li><i class='bx bx-purchase-tag-alt'></i> <a routerLink="/blog">Marketing</a></li>
                        </ul>
                        <h3><a routerLink="/blog-details">The Home of Technology is in Front of You</a></h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="blog-card">
                    <a routerLink="/blog-details"><img src="assets/img/blog/blog2.png" alt="Images"></a>
                    <div class="content">
                        <ul>
                            <li><i class='bx bx-time-five'></i>10 July 2020</li>
                            <li><i class='bx bx-purchase-tag-alt'></i> <a routerLink="/blog">SEO</a></li>
                        </ul>
                        <h3><a routerLink="/blog-details">SEO Best Practices Mobile Friendliness</a></h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="blog-card">
                    <a routerLink="/blog-details"><img src="assets/img/blog/blog3.png" alt="Images"></a>
                    <div class="content">
                        <ul>
                            <li><i class='bx bx-time-five'></i> 19 June 2020</li>
                            <li><i class='bx bx-purchase-tag-alt'></i> <a routerLink="/blog">SEO & Marketing</a></li>
                        </ul>
                        <h3><a routerLink="/blog-details">15 SEO Practices Website Architecture</a></h3>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="blog-shape">
        <div class="shape1"><img src="assets/img/shape/shape1.png" alt="Images"></div>
        <div class="shape2"><img src="assets/img/shape/shape5.png" alt="Images"></div>
        <div class="shape3"><img src="assets/img/shape/shape4.png" alt="Images"></div>
        <div class="shape4"><img src="assets/img/shape/shape6.png" alt="Images"></div>
    </div>
</div>

<app-footer-style-one></app-footer-style-one>